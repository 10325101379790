<template>
    <div>{{data[0].door}}</div>
</template>

<script>
import EquipmentData from '../../public/data/equipment.js';

export default {
    data: function(){
        return {
            data: EquipmentData
        }
    },
    mounted: function(){
        console.log(EquipmentData);
        for(var prop in EquipmentData[0]){
            if(Object.prototype.hasOwnProperty.call(EquipmentData[0], prop)){
                console.log(prop);
            }
        }
    }

}
</script>