function randomMix(){
    return String.fromCharCode(65+Math.floor(Math.random() * 26)) + ("000000" + Math.floor(Math.random() * 10000000)).substr(-6) + String.fromCharCode(65+Math.floor(Math.random() * 26))+String.fromCharCode(65+Math.floor(Math.random() * 26))+String.fromCharCode(65+Math.floor(Math.random() * 26));
}

var equipment = {};

function maint(s){
    var arr = [];
    for (var i = 0; i < 10; ++i){
        arr.push('' + (s * 10 + i));
    }
    return arr;
}

for (var i = 0; i< 250; ++i)
{
    equipment[i] = {
        'id': i,
        'trailer-number' :  randomMix(),
        'plate-number' :  randomMix(),
        'vin': randomMix() + randomMix(),
        'year': (1990 + Math.floor(Math.random() * 30)),
        'custom-number': Math.floor(Math.random() * 4) ? randomMix() : '',
        'make': 'SomeMake',
        'description': 'SomeDescription',
        'star-owned': !!(Math.floor(Math.random() * 7) % 2),
        'active':   !!(Math.floor(Math.random() * 99) % 90),
        'door': '' + Math.floor(Math.random() * 3),
        'suspension': '' + Math.floor(Math.random() * 3),
        'wall': '' + Math.floor(Math.random() * 5),
        'gps': !!(Math.floor(Math.random() * 8) % 5),
        'compliance': Math.floor(Math.random() * 25),
        'maintenance': maint(i % 10)
    }
}
export default equipment
